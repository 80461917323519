import React, { useState } from 'react';
import { Link } from 'gatsby';
import Button from '../atoms/inputs/Button';
import Headline from '../atoms/typography/Headline';

export default function AmbassadorApplicant() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [biography, setBiography] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const inputStyles =
    'block w-full px-4 py-3 h-12 appearance-none rounded-3xl border-0 text-base bg-grey-gradient text-grey-25 placeholder-grey-25 focus:outline-none focus:ring-1 focus:ring-highlight shadow-md';

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!name || !email || !biography || !city || !country)
      return setErrorMessage('Please fill in all values');

    try {
      await fetch('/.netlify/functions/ambassador-applicant', {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          biography,
          city,
          country
        })
      })
        .then((res) => {
          if (res.status === 200) {
            setName('');
            setEmail('');
            setBiography('');
            setCity('');
            setCountry('');
            setErrorMessage('Thank you for your submission');
            setIsLoading(false);
          }
          return res.json();
        })
        .catch((err) => console.log(err.message));
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <div
      id='ambassador-sign-up-form'
      className='relative z-10 text-center w-full bg-white px-0 lg:px-10 py-4 xl:py-8 rounded-3xl'
    >
      <div className='max-w-xl text-center mx-auto mb-8 lg:mb-8'>
        <h3 className='text-3xl md:text-4xl lg:text-5xl text-light-grey font-light lg:leading-[62.5px] mb-4'>
          Become a
          <span className='text-default whitespace-nowrap block'>
                        Community Ambassador
                    </span>
        </h3>
        <p className='text-lg lg:text-xl'>
          Register your interest with flockx and start earning with
          your passion for sharing local activities!
        </p>
      </div>
      <form onSubmit={handleSubmit} className='mx-auto max-w-md'>
        <div className='flex flex-col  items-start justify-start w-full'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder='Name'
          />

          <input
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder='Email'
          />

          <input
            type='city'
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder='City'
          />

          <input
            type='country'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
            className={inputStyles + ' mb-4'}
            placeholder='Country'
          />

          <textarea
            placeholder='Tell us about yourself...'
            value={biography}
            onChange={(e) => {
              if (biography.length > 254) {
                setErrorMessage(
                  'Your can only be 255 characters.'
                );
              } else {
                setErrorMessage('');
              }
              setBiography(e.target.value);
            }}
            required
            className={inputStyles + ' mb-4 min-h-[120px]'}
          />
        </div>
        <p
          className={`my-3 text-xs lg:text-base sm:mt-3 text-grey-25 font-light max-w-xs md:max-w-[unset] mx-auto`}
        >
          By providing your email, you agree to our{' '}
          <Link to='/legal/terms' className=' text-highlight'>
            terms of service
          </Link>
          .
        </p>
        <div className='mx-auto flex items-center justify-center h-full relative'>
          <Button
            type='submit'
            label='Submit'
            className='min-w-[200px]'
            isLoading={isLoading}
            minWidth={213}
          />
        </div>
        {errorMessage && (
          <p className='text-center my-4 lg:my-6 text-default text-xl lg:text-2xl'>
            {errorMessage}
          </p>
        )}
      </form>
    </div>
  );
}
