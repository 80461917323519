import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import DownloadAppCTA from '../components/atoms/callouts/DownloadAppCTA';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import Spacer from '../components/atoms/utility/Spacer';
import AmbassadorApplicant from '../components/organisms/AmbassadorApplicant';

export default function AmbassadorsPage({
                                          data: {
                                            heroImg,
                                            mobileHeroImg,
                                            panelOneImg,
                                            panelTwoImg,
                                            panelThreeImg,
                                            panelFourImg
                                          }
                                        }) {
  return (
    <BaseLayout
      heroContent={PartnershipHeroContent}
      mobileHeroImg={mobileHeroImg}
      heroImg={heroImg}
      heroAlt='A camera used for exploring the city.'
    >
      <SEO
        title='Join Our Ambassador Program in Real Time for an Impact'
        keywords={[
          'mobile app',
          'influencer',
          'community',
          'exploration',
          'experience',
          'inspiration',
          'flockx',
          'partnership',
          'musicians',
          'live music'
        ]}
        description={
          'Join the flockx Community App Ambassador Program and make a real-time impact. Connect with like-minded individuals, participate in trending activities, and support each other\'s growth in a supportive community.'
        }
      />
      <div>
        <Spacer sizeY={1} className='block xl:block' />
        <Spacer className='block sm:hidden' />

        <div className='lg:grid gap-10 lg:gap-24 grid-cols-12 justify-center items-center'>
          <div className='col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4'>
            <h3 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5'>
              Recommend{' '}
              <span className='text-secondary-blue whitespace-nowrap block'>
                                Activities & Events 🏒
                            </span>
            </h3>
            <p className='text-lg sm:text-2xl md:text-3xl lg:text-4xl'>
              Share local events, activities, and experiences
              happening in your favorite Communities.{' '}
            </p>
          </div>
          <div className='col-span-6 max-w-[700px] mx-auto'>
            <GatsbyImage image={getImage(panelOneImg)} alt={''} />
          </div>
        </div>

        <Spacer className='block sm:hidden' />
        <Spacer sizeY={2} />

        <div className='flex flex-col lg:grid lg:gap-24 grid-cols-12 justify-center items-center'>
          <div className='col-span-6 order-2 lg:order-1 max-w-[700px] mx-auto'>
            <GatsbyImage image={getImage(panelTwoImg)} alt={''} />
          </div>
          <div className='col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 order-1 lg:order-2'>
            <h3 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5'>
              Authentic{' '}
              <span className='text-secondary-orange whitespace-nowrap block'>
                                Word-of-Mouth 🍕
                            </span>
            </h3>
            <p className='text-lg sm:text-2xl md:text-3xl lg:text-4xl'>
              Reveal local trends which keep others informed on
              local happenings in real-time.{' '}
            </p>
          </div>
        </div>

        <Spacer className='block sm:hidden' />
        <Spacer sizeY={2} />

        <div className='lg:grid gap-10 lg:gap-24 grid-cols-12 justify-center items-center'>
          <div className='col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4'>
            <h3 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5'>
              Promote Your{' '}
              <span className='text-secondary-cyan whitespace-nowrap block'>
                                Local Businesses 🥇
                            </span>
            </h3>
            <p className='text-lg sm:text-2xl md:text-3xl lg:text-4xl mb-8 lg:mb-12'>
              Our platform champions genuine local businesses and
              experiences, contributing to the growth of local
              communities.{' '}
            </p>
          </div>
          <div className='col-span-6 max-w-[700px] mx-auto'>
            <GatsbyImage image={getImage(panelThreeImg)} alt={''} />
          </div>
        </div>

        <Spacer className='block sm:hidden' />
        <Spacer sizeY={2} />

        <div className='flex flex-col lg:grid lg:gap-24 grid-cols-12 justify-center items-center'>
          <div className='col-span-6 order-2 lg:order-1 max-w-[700px] mx-auto'>
            <GatsbyImage image={getImage(panelFourImg)} alt={''} />
          </div>
          <div className='col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 order-1 lg:order-2'>
            <h3 className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5'>
              Earn Real{' '}
              <span className='text-secondary-orange whitespace-nowrap block'>
                                Rewards 🔥
                            </span>
            </h3>
            <p className='text-lg sm:text-2xl md:text-3xl lg:text-4xl'>
              Boost the visibility of community activities and
              events to earn cash rewards.{' '}
            </p>
          </div>
        </div>

        <Spacer className='block sm:hidden' />
        <Spacer sizeY={2} />
        <div className='mx-auto w-full flex items-center justify-center'>
          <AmbassadorApplicant />
        </div>
        <Spacer className='block sm:hidden' />
        <Spacer />
      </div>
    </BaseLayout>
  );
}

function PartnershipHeroContent() {
  return (
    <div className='-mt-[15%] sm:mt-[5%] lg:mt-0'>
      <div className='text-center sm:text-left sm:mt-6 lg:mt-0'>
        <div className='mb-10 md:mb-0'>
          <PrimaryHeader
            headerText='Turn Your Passion Into Profit'
            className='text-5xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[72px] leading-[1.3] md:leading-[1.4] xl:leading-[1.3] mb-2 sm:mb-4 lg:mb-4 text-white capitalize max-w-[330px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl mx-auto w-full lg:mr-0 sm:ml-0 lg:ml-0 font-light'
          />

          <h2
            className='text-lg sm:text-xl md:text-2xl xl:text-[32px] md:leading-[1.3] lg:leading-[1.6] xl:leading-[48px] mb-4 md:mb-4 lg:mb-8 text-white mx-auto font-light max-w-[240px] sm:max-w-sm lg:max-w-lg sm:mx-0'>
            Help us build the ultimate event{' '}
            <span className='font-bold capitalize whitespace-nowrap block'>
                            discovery platform
                        </span>
          </h2>
          <DownloadAppCTA
            containerStyles='justify-center sm:justify-start'
            id='partnership'
          />
          <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
            Available in the US
          </p>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
    query AmbassadorsPageQuery {
        heroImg: file(name: { eq: "ambassador-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        mobileHeroImg: file(name: { eq: "ambassador-hero-mobile" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        panelOneImg: file(name: { eq: "ambassador-img-1" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        panelTwoImg: file(name: { eq: "ambassador-img-2" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        panelThreeImg: file(name: { eq: "ambassador-img-3" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        panelFourImg: file(name: { eq: "ambassador-img-4" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
